import $ from 'jquery';
import { $Elements, Events, Classes, Elements } from './constants';
import { disableBodyScroll, enableBodyScroll, scrollTo } from './helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Menu ----------

	$('.menu-toggle, a[href="#menu-wrapper"]').click(function(e) {
		e.preventDefault();
		const widgetColumn = document.querySelector('.widget-column')

		if($Elements.body.hasClass(Classes.menuOpen)) {
			$Elements.body.removeClass(Classes.menuOpen);
			enableBodyScroll();
			if(widgetColumn) {
				widgetColumn.classList.remove('hidden');
			}
		}
		else {
			if(widgetColumn) {
				widgetColumn.classList.add('hidden');
			}

			$Elements.body.addClass(Classes.menuOpen);
			disableBodyScroll();
		}
	});

	$Elements.MenuWrapper.each(function() {
		const images = $(this).find('.col-image');
		const links = $(this).find('.menu-links');
		const menuItems = links.find('.menu-item');

		menuItems.on('mouseenter touchstart', async function() {
			Elements.MainWrapper.dataset.activeMenu = this.dataset.menu;
			menuItems.removeClass(Classes.active);
			this.classList.add(Classes.active);

			const leaving = images.find('.' + Classes.active);
			const active = images.find('.menu-image[data-menu="' + this.dataset.menu + '"]');
			if(leaving.length && active.length && !leaving[0].isSameNode(active[0])) {
				leaving.removeClass(Classes.active);
				active.addClass(Classes.active);
			}
		});
	});

	$('.menu-overlay').click(function(e) {
		e.preventDefault();

		$('.menu-toggle').trigger('click');
	});

	$('.navbar-menu a, .menu-wrapper a').click(function(e) {

		const linkTarget = this.getAttribute('href');
		const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

		if(sectionTarget.substring(0, 1) === '#') {

			e.preventDefault();

			const section = $(sectionTarget);

			if(section.length) {

				window.history.replaceState(null, null, sectionTarget);

				$Elements.body.removeClass(Classes.menuOpen);
				enableBodyScroll();
				scrollTo(section);
			}
			else if(sectionTarget !== '#') {
				window.location = linkTarget;
			}
		}
	});
});

$Elements.document.on(Events.page.loaded, () => {

	const linkTarget = window.location.href;
	const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

	if(sectionTarget.substring(0, 1) === '#') {

		const section = $(sectionTarget);

		if(section.length) {
			scrollTo(section, 0);
		}
	}
});
