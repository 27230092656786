import { gsap } from 'gsap';
import $ from 'jquery';
import barba from '@barba/core';

import { $Elements, BarbaLinks, Breakpoints, Classes, Durations, Elements, Events, pauseAnimations, resetAnimations } from '../global/constants';
import { disableBodyScroll, enableBodyScroll, replaceBodyClasses, scrollPosition, smoothScrollTo, wait, waitForImage, waitForVideo } from '../global/helpers';

// ---------- Barba Initialisation ----------

barba.init({
	debug: false,
	timeout: 10000,
	preventRunning: true,
	prevent: ({ el }) => !$(el).is(BarbaLinks),
	transitions: [{
		debug: true,
		sync: true,
		once: async() => {
			$Elements.document.on('mousedown touchstart', BarbaLinks, e => {

				if((typeof (window.ontouchstart) !== 'undefined' && e.type === 'mousedown') || (e.which === 2 || e.which === 3 || e.button === 1 || e.button === 2 || e.metaKey || e.shiftKey || navigator.platform.toUpperCase().indexOf('WIN') !== -1 && e.ctrlKey)) return;

				const target = new URL(e.currentTarget.href);

				if(target.pathname !== window.location.pathname) {
					Elements.LoadingBar.classList.add(Classes.loading);
				}
			});

			$Elements.window.on('load', pageLoaded);
			$Elements.document.trigger(Events.page.loaded);
		},
		enter: async() => $Elements.document.one(Events.page.transition, pageLoaded),
		leave: async(data) => {

			Elements.MainWrapper.classList.remove(Classes.initialLoad);
			Elements.LoadingOverlay.classList.remove(Classes.initialLoad);

			document.body.classList.add(Classes.leaving);

			pauseAnimations();

			if(isMenuImageLink(data) && window.innerWidth >= Breakpoints.mobile) {
				await menuImageTransition(data);

			}
			else {
				await defaultTransition(data);
			}

			return true;
		},
		after: async(data) => {

			smoothScrollTo(0, 0, 0);

			replaceBodyClasses(data.next);

			$('.' + Classes.transitionClone).remove();

			$Elements.document.trigger(Events.page.transition);

			return true;
		}
	}]
});

// ---------- Global ----------

async function pageLoaded() {

	resetAnimations();
	$Elements.document.trigger(Events.page.enter);
	Elements.MainWrapper.classList.remove(Classes.loading);
	Elements.LoadingBar.classList.remove(Classes.loading);
	Elements.LoadingOverlay.classList.remove(Classes.loading);
	$Elements.ContentWrapper.find('> .content-section:first-child').addClass(Classes.active);

	return true;
}

async function waitForPage(page = Elements.PageWrapper, duration = Durations.page.ms) {

	await wait(duration);

	const heroVideo = page.querySelector(`.${Classes.ContentWrapper} > .content-section:first-child .video`);
	const heroImage = page.querySelector(`.${Classes.ContentWrapper} > .content-section:first-child .image`);
	if(heroVideo) {
		await waitForVideo(heroVideo);
	}
	else if(heroImage) {
		await waitForImage(heroImage);
	}

	return true;
}

async function saveCurrentScrollPosition(data) {

	const scroll = scrollPosition();

	data.current.container.classList.add(Classes.leaving);
	data.current.container.style.top = -scroll.scrollTop + 'px';

	smoothScrollTo(0, 0, 0);

	return true;
}

function prepareImageForTransition(image) {

	$(image).find('.image').each(function() {
		$(this).attr('sizes', window.innerWidth + 'px');
	});

	const bounds = image.getBoundingClientRect();

	gsap.set(image, {
		position: 'fixed',
		top: bounds.top,
		left: bounds.left,
		width: bounds.width,
		height: bounds.height,
		zIndex: 100000,
	});
}

// ---------- Menu Link Transition ----------

function isMenuImageLink(data) {
	return (typeof data.trigger !== 'string' && data.trigger.classList.contains('barba-menu-link') && (!data.trigger.classList.contains('barba-submenu-link')) && (!data.trigger.classList.contains('barba-menu-link-clipped')));
}

function isMenuImageClipped(data) {
	return (typeof data.trigger !== 'string' && data.trigger.classList.contains('barba-menu-link-clipped') && (!data.trigger.classList.contains('barba-submenu-link')));
}

// ---------- Parallax Page Transition ----------
async function menuImageTransition(data) {

	const image = document.querySelector('.menu-image[data-menu="' + data.trigger.dataset.image + '"]');

	if(image && window.innerWidth >= Breakpoints.mobile) {

		await saveCurrentScrollPosition(data);
		disableBodyScroll();

		prepareImageForTransition(image);

		$(data.next.container).find('.menu-wrapper, .header-wrapper').addClass(Classes.hidden);

		await gsap.to(image, {
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			autoRound: false,
			duration: Durations.page.s,
		});

		const clone = image.querySelector('.section-image, .column-image');

		const nextHero = data.next.container.querySelector('.hero-section .column-image, .introduction-section .section-image');


		document.body.appendChild(clone);

		clone.classList.add(Classes.transitionClone);

		document.body.classList.remove(Classes.menuOpen);

		data.current.container.classList.add(Classes.hidden);

		nextHero.replaceWith(clone);

		clone.classList.remove(Classes.transitionClone);

		await waitForPage(data.next.container);

		$(data.next.container).find('.menu-wrapper, .header-wrapper').removeClass(Classes.hidden);

		enableBodyScroll();

	}
	else {
		await defaultTransition(data);
	}

	return true;
}

// ---------- Default Transition ----------

async function defaultTransition(data) {

	Elements.LoadingOverlay.classList.add(Classes.loading);

	await saveCurrentScrollPosition(data);

	disableBodyScroll();

	if(document.body.classList.contains(Classes.menuOpen)) {
		document.body.classList.remove(Classes.menuOpen);
	}

	await waitForPage(data.next.container);

	enableBodyScroll();

	return true;
}
