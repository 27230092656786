import $ from 'jquery';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { $Elements, Durations, Events, SectionsRegiondo, Classes, Animations } from './constants';


$Elements.document.on(Events.page.enter, () => {

	// ---------- Carousel ----------

	$('.content-section:not(.reviews-section,.wines-range-section,.wines-presentation-section) .section-carousel, .content-section .column-carousel').each(function() {
		if(!this.swiper) {
			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: true,
				observer: true,
				modules: [Navigation, Pagination],
				pagination: {
					el: this.querySelector('.swiper-pagination'),
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	// ---------- Slideshow ----------

	$('.content-section .section-slideshow, .content-section .column-slideshow').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: true,
				observer: true,
				autoplay: true,
				effect: 'fade',
				modules: [Autoplay, EffectFade, Navigation, Pagination],
				fadeEffect: {
					crossFade: true,
				},
				pagination: {
					el: this.querySelector('.swiper-pagination'),
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	// ---------- Wine presentation Carousel ----------
	$('.wines-presentation-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 10,
				modules: [Navigation],
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
				breakpoints: {
					1024: {
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 0,
					},
				},
				loop: false,
				observer: true,
			});
		}
	});

	// ---------- Wines range Carousel ----------
	$('.wines-range-section .section-carousel').each(function() {
		if(!this.swiper) {
			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				slidesPerView: 1.5,
				spaceBetween: 10,
				centeredSlides: false,
				breakpoints: {
					768: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
					1024: {
						slidesPerView: 4,
						spaceBetween: 30,
					},
					1290: {
						slidesPerView: 5,
						spaceBetween: 30,
						centeredSlides: true,
					}
				},
				loop: false,
				observer: true,
				modules: [Navigation, Pagination],
				pagination: {
					el: this.querySelector('.swiper-pagination'),
					clickable: true
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
				on: {
					init: function() {
						checkNavigationVisibility(this);
					},
					resize: function() {
						checkNavigationVisibility(this);
					}
				},
			});
			if(window.innerWidth >= 1024) {
				swiper.slideTo(2, 0, false);
			}

			function checkNavigationVisibility(swiper) {
				const totalSlides = swiper.slides.length;
				let slidesPerViewCurrent = swiper.params.slidesPerView;

				if(typeof slidesPerViewCurrent === 'string' || slidesPerViewCurrent instanceof String) {
					slidesPerViewCurrent = parseFloat(slidesPerViewCurrent);
				}
				else if(typeof slidesPerViewCurrent === 'object') {
					slidesPerViewCurrent = slidesPerViewCurrent[swiper.currentBreakpoint] || 1.5;
				}

				if(totalSlides <= slidesPerViewCurrent) {
					$(swiper.navigation.nextEl).hide();
					$(swiper.navigation.prevEl).hide();
				}
				else {
					$(swiper.navigation.nextEl).show();
					$(swiper.navigation.prevEl).show();
				}
			}
		}
	});

});
