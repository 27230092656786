import $ from 'jquery';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import GLightbox from 'glightbox';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { $Elements, Animations, Classes, Events } from './constants';
import { fixMobileHover, getCookiesAllowed, initGTM, isDisclaimerAccepted, isTouchScreen, setCookiesAllowed, setDisclaimerAccepted, smoothScrollTo, sortAnimations, wait } from './helpers';

// ---------- LazyLoad Config ----------

lazySizes.cfg.throttleDelay = 66;
lazySizes.cfg.preloadAfterLoad = true;

// ---------- Fix Scroll ----------

history.scrollRestoration = "manual";

// ---------- Touch Screen ----------

if(isTouchScreen()) {
	$Elements.html.addClass('touchscreen');
}

// ---------- Fix VH ----------

$Elements.window.on('resize', () => document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`));
$Elements.window.trigger('resize');

// ---------- jQuery Related ----------

$Elements.document.on(Events.page.enter, async() => {

	// ---------- Fix Mobile Hover ----------

	fixMobileHover();

	// ---------- Handle Aniamtions ----------

	ScrollTrigger.matchMedia(sortAnimations(Animations));

	// ---------- Links ----------

	$Elements.PageWrapper.on('click', 'a[href="#"], .void-link', (e) => e.preventDefault());

	$Elements.PageWrapper.on('click', '[data-scroll-to], [target="scroll-to"]', function(e) {
		e.preventDefault();

		const self = $(this);
		const target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

		if(target.length) {
			smoothScrollTo(target[0]);
		}
	});

	$Elements.PageWrapper.on('click', '.select-dropdown .dropdown-menu .btn', function() {
		$(this).closest('.dropdown').find('.dropdown-toggle').html($(this).text());
	});

	// ---------- GLightbox ----------

	$('[target="lightbox"]').each(function() {

		if($(this).attr('data-size') === 'full') {

			GLightbox({
				selector: 'a[href="' + this.attributes.href.value + '"][target="lightbox"][data-size="full"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				width: 'auto',
				height: 'auto',
				videosWidth: 1280,
				skin: 'fullscreen',
			});
		}
		else {

			GLightbox({
				selector: 'a[href="' + this.attributes.href.value + '"][target="lightbox"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				videosWidth: 1280,
			});
		}
	});

	$('[target^="lightbox-gallery"]').each(function() {

		if($(this).attr('data-size') === 'full') {

			GLightbox({
				selector: 'a[target="' + this.attributes.target.value + '"][data-size="full"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				width: 'auto',
				height: 'auto',
				videosWidth: 1280,
				skin: 'fullscreen',
			});
		}
		else {

			GLightbox({
				selector: 'a[target="' + this.attributes.target.value + '"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				videosWidth: 1280,
			});
		}
	});

	// ---------- Cookies ----------

	initGTM();

	if(!getCookiesAllowed()) {

		const cookies = document.querySelector('#cookies-allowed');

		if(cookies) {

			cookies.classList.remove(Classes.hidden);
			await wait(100);
			cookies.classList.add(Classes.show);

			$(cookies).on('click', '[data-bs-dismiss="alert"]', function() {
				setCookiesAllowed(this.dataset.allowed);
			});
		}
	}

	// ---------- Disclaimer ----------

	if(!isDisclaimerAccepted()) {

		const disclaimer = document.querySelector('#disclaimer');

		if(disclaimer) {

			disclaimer.classList.remove(Classes.hidden);
			$Elements.body.addClass(Classes.disclaimer);

			$(disclaimer).on('close.bs.alert', function() {
				setDisclaimerAccepted();
				$Elements.body.removeClass(Classes.disclaimer);
			});
		}
	}

	// ---------- Anchor ----------

	const heroSection = document.querySelector('.hero-section, .introduction-section, .hero-product-section');

	if(heroSection) {
		const button = heroSection.querySelector('.anchor');

		if(button) {
			const sections = document.querySelectorAll('section');
			if(sections.length >= 2) {
				sections[1].id = "heroScroll";
			}
		}
	}

	// ---------- Application modal ----------

	$('.application-modal').each(function() {
		const fileInput = document.getElementById('cv');
		const fileNameDisplay = document.getElementById('file-name');
		const removeFileButton = document.getElementById('remove-file');
		const buttonClose = document.querySelector('.close');
		const form = document.querySelector('form');


		fileInput.addEventListener('change', function() {
			if(fileInput.files.length > 0) {
				let fileName = fileInput.files[0].name;
				fileNameDisplay.textContent = fileName;
				removeFileButton.classList.remove('hidden');
			}
		});

		removeFileButton.addEventListener('click', function() {
			fileInput.value = "";
			fileNameDisplay.textContent = "";
			removeFileButton.classList.add('hidden');
		});

		buttonClose.addEventListener('click', function() {
			form.reset();
			fileNameDisplay.textContent = "";
			removeFileButton.classList.add('hidden');
		});
	});


});




