import $ from 'jquery';
import {  Swiper } from 'swiper';
import { $Elements, Durations, Events } from './constants';
import { loadExternalScript } from './helpers';
import { Navigation, Pagination } from 'swiper/modules';

// ---------- Regiondo ----------


$Elements.document.on(Events.page.enter, () => {

	if(document.getElementById('ctaWidgetRegiondo')) {
		loadExternalScript('<script id="regiondo-js" src="https://cdn.regiondo.net/js/integration/bookingwidget/bookingwidget.js"></script>');
	}
	else {
		const script = document.getElementById('regiondo-js');

		if(script) {
			script.nextElementSibling.remove();
			script.remove();
		}
	}

	// ---------- Modal ----------
	$('.btn-modal').on('click', function() {

		const id = $(this).data('id');

		const modalHtml = `
                <div class="modal fade" id="widgetModal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content" data-lenis-prevent>
                            <div class="modal-header">
                                <button id="closeWidget" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                               <booking-widget widget-id=${id}></booking-widget>
                            </div>
                        </div>
                    </div>
                </div>
            `;

		$('#widgetModal').remove();

		$('body').append(modalHtml);

		$('#widgetModal').modal('show');

		$(document).on('click', function(event) {
			if(!$(event.target).closest('#widgetModal .modal-body').length) {
				$('#widgetModal').remove();
			}
		})

		$('#closeWidget').on('click', function() {
			$('#widgetModal').remove();
		})
	});

	// ---------- Reviews Carousel ----------
	$('.reviews-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				slidesPerView: 1,
				spaceBetween: 20,
				breakpoints: {
					768: {
						slidesPerView: 2
					},

					1280: {
						slidesPerView: 3
					},
					1920: {
						slidesPerView: 4
					},
				},
				loop: false,
				observer: true,
				modules: [Navigation, Pagination],
				pagination: {
					el: this.querySelector('.swiper-pagination'),
					clickable: true
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

});
