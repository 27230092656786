import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Breakpoints, Classes, Events, SectionsRegiondo, tcVarName } from '../global/constants';
import { elementPosition } from '../global/helpers';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Menu ----------

	$Elements.HeaderWrapper.each(function() {

		Animations.global.push(() => {


			gsap.timeline({
				scrollTrigger: {
					start: 1,
					end: 'max',
					onEnter: () => {
						if(window.location.hash) {
							this.classList.remove(Classes.shrink)
						}
					},
					onUpdate: (self) => {
						(self.direction === -1) ? this.classList.remove(Classes.shrink) : this.classList.add(Classes.shrink);
						(self.progress > 0) ? this.classList.add(Classes.shrinkable) : this.classList.remove(Classes.shrinkable);
					}
				}
			});
		});
	});

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});
		});
	});

	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});

	// ---------- Hero section ----------

	$('.hero-section').each(function() {
		const title = this.querySelector('.content');
		const image = this.querySelector(' .image');

		if(title) {
			gsap.to(title, {
				scrollTrigger: {
					trigger: this,
					scrub: true,
					start: "top top",
				},
				y: 500,
			});
		}

		if(image) {
			gsap.to(image, { scale: 1, duration: 1.5 });

			gsap.to(image, {
				scrollTrigger: {
					trigger: this,
					scrub: true,
					start: "40% top",
				},
				y: '10vh',
				ease: 'none',
			});
		}
	});

	// ---------- Animated text-images-slider-section ---------

	$('.text-images-slider-section, .link-images-slider-section, .images-slider-section').each(function() {

		Animations.global.push(() => {
			const section = this;
			const content = section.querySelector(':scope .section-columns');

			if(content && section) {
				const columns = gsap.utils.toArray('.content-column', content);
				const contentWidth = () => (columns.reduce((val, column) => val + column.offsetWidth, 0)) + Math.max(0, window.innerWidth - Breakpoints.xxl);
				const triggerOffset = () => {
					const position = elementPosition(content);
					return position.top;
				}

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: section,
						pin: true,
						scrub: true,
						invalidateOnRefresh: true,
						start: () => triggerOffset(),
						end: () => '+=' + contentWidth(),
					},
				});

				timeline.to(content, { x: () => window.innerWidth - contentWidth(), ease: "none" });
			}
		});
	});

	// ---------- Animated simple-text-section ----------

	$('.simple-text-section').each(function() {

		Animations.mobile.min.push(() => {

			const content = this.querySelector('.content');

			if(content) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top 75%',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					}
				});

				const lineHeight = () => {
					const height = '300%';

					gsap.set(content, { height: 'auto' });
					gsap.set(content, { lineHeight: height });
					gsap.set(content, { height: content.offsetHeight });

					return height;
				}

				timeline.fromTo(
					content,
					{ lineHeight: () => lineHeight(), ease: 'none' },
					{ lineHeight: () => '150%', ease: 'none' }
				);

				timeline.to(content, { [tcVarName('after-opacity')]: 1, ease: 'none', duration: .3 }, 0);
				timeline.to(content, { [tcVarName('after-width')]: '70px', ease: 'none' }, 0.1);
			}
		});
	});

	// ---------- Animated simple-text-image-section ----------

	$('.simple-text-image-section, .text-image-quote-section, .list-tour-section').each(function() {
		const section = this;

		Animations.mobile.min.push(() => {

			const image = this.querySelector('.image-column');
			const quote = this.querySelector('.quote-column:not(.image-empty)');

			if(image && section) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: section,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				timeline.fromTo(
					image,
					{ y: '10vh', ease: 'none' },
					{ y: '-10vh', ease: 'none' }
				);

				const opacity = gsap.timeline({
					scrollTrigger: {
						trigger: section,
						start: 'top bottom',
						end: 'top top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				opacity.fromTo(
					image,
					{ opacity: 0, ease: 'none' },
					{ opacity: 1, ease: 'none' }
				);
			}

			if(quote && section) {
				gsap.to(quote, {
					scrollTrigger: {
						trigger: section,
						scrub: true,
						start: "top top",
						end: "bottom center",
					},
					y: '5vh',
					ease: 'none',
				});
			}
		});
	});

	// ---------- Animated text-background-section ----------

	$('.text-background-section').each(function() {
		const section = this;

		if(section) {
			Animations.global.push(() => {

				const content = section.querySelector('.highlighted');

				if(content) {

					const timeline = gsap.timeline({
						scrollTrigger: {
							trigger: content,
							start: 'top bottom',
							end: 'top top',
							scrub: true,
							invalidateOnRefresh: true,
						}
					});

					timeline.to(content, { [tcVarName('after-opacity')]: 1, ease: 'none', duration: .3 }, 0);
					timeline.to(content, { [tcVarName('after-height')]: '70px', ease: 'none' }, 0.1);
				}
			});

			Animations.mobile.max.push(() => {
				const image = section.querySelector('.image');

				if(image) {
					gsap.to(image, {
						scrollTrigger: {
							trigger: section,
							scrub: true,
							invalidateOnRefresh: true,
							start: '50% top',
						},
						y: '10rem',
						ease: 'none'
					});

				}
			});

			Animations.mobile.min.push(() => {
				if(!section) return;

				const image = section.querySelector(':scope .image');

				if(image) {

					const timeline = gsap.timeline({
						scrollTrigger: {
							trigger: section,
							start: 'top bottom',
							end: 'bottom top',
							scrub: true,
							invalidateOnRefresh: true,
						},
					});

					timeline.to(
						image,
						{ y: () => "30rem", ease: 'none' }
					);
				}
			});
		}

	});

	// ---------- Regiondo Widget Fade-in ----------
	$(SectionsRegiondo.initSectionWidget).each(function() {

		Animations.mobile.min.push(() => {
			const reviewSection = document.querySelector(SectionsRegiondo.endSectionWidget);
			let endTrigger = document.querySelector('.footer');

			if(reviewSection) {
				endTrigger = reviewSection;
			}

			const modal = document.querySelector('.modal');
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: '50% 56%',
					endTrigger: endTrigger,
					end: 'bottom top',
					toggleClass: {
						targets: $('.widget-column'),
						className: Classes.show
					},
				}
			});
		})
	});

	// ---------- Regiondo Widget Fade-out-down ----------
	$(SectionsRegiondo.endSectionWidget).each(function() {
		Animations.mobile.min.push(() => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top 90%',
					endTrigger: SectionsRegiondo.endSectionWidget,
					end: 'bottom top',
					toggleClass: {
						targets: $('.widget-column'),
						className: Classes.fadeDown
					},
				}
			});
		});
	});

	// ---------- History Section ----------

	$('.history-section .text-column').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});
		});
	});

	$('.history-section').each(function() {
		Animations.mobile.min.push(() => {
			const section = this.querySelector('.section-columns');

			gsap.to(section, {
				scrollTrigger: {
					trigger: section,
					start: 'top center',
					end: 'bottom center',
					scrub: true,
					onUpdate: (self) => {
						const progress = self.progress;
						section.style.setProperty('--scale-y', 1 - progress);
					}
				},
				ease: 'power1.out',
			});
		})
	})
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
